package com.koduok.lists.feature.terms

import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import com.koduok.lists.component.topBar.TopAppBar
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.TermsRoute

val termsScreen
    get() = screen<TermsRoute> {
        val router = LocalRouter.current
        TermsScreen(it.routeType, onClickBack = router::pop)
    }

@Composable
private fun TermsScreen(
    type: TermsRoute.Type,
    onClickBack: () -> Unit,
) {
    Scaffold(
        topBar = {
            TopAppBar(
                title = {
                    Text(
                        when (type) {
                            TermsRoute.Type.Terms -> "Naudojimosi sąlygos"
                            TermsRoute.Type.Privacy -> "Privatumo politika"
                        }
                    )
                },
                navigationIcon = { TopBarBackButton(onClickBack) }
            )
        },
        content = {
            val text = when (type) {
                TermsRoute.Type.Terms -> buildString {
                    append("Bendrosios nuostatos ir platformos naudojimo taisyklės\n")
                    append("\n")
                    append("Kiekvieną kartą naudodamiesi Portalu turite būti susipažinę su šiomis Sąlygomis, ypač, jeigu naudojatės patarimais susijusiais su fizine ar psichine sveikata. Naudodamiesi šia Svetaine patvirtinate, jog esate susipažinę su šiomis Sąlygomis ir įsipareigojate besąlygiškai laikytis jų. Platforma yra skirta vartotojams sudaryti norimų prekių sąrašą su nuorodomis į kitas svetaines ir juo pasidalinti su kitais platformos vartotojais. Vartotojams suteikiama galimybė matyti, kurios norimos prekės yra laisvos ir kurios rezervuotos. Platforma neprisiima atsakomybės užtikrinti šių statusų fakto atitikimą - tai pačių vartotojų atsakomybė. Platforma neįsipareigoja užtikrinti pateiktų kainų tikrumo, tai yra trečiųjų šalių pateikta arba vartotojų sukurta informacija. Platforma pasilieka teisę deaktyvuoti vartotoją ar pašalinti sąrašą kilus įtarimui, jog jis neatitinka teisės aktų ar moralės normų.\n")
                    append("\n")
                    append("\n")
                    append("Prieinamumas ir atsakomybė\n")
                    append("\n")
                    append("Nors dedame visas pastangas, kad Svetainė būtų prieinama visada, mes neprisiimame atsakomybės, jei dėl techninių priežasčių bus negalima užtikrinti nepertraukiamo Portalo prieinamumo.\n")
                    append("\n")
                    append("\n")
                    append("Klientų aptarnavimas\n")
                    append("\n")
                    append("Iškilus klausimams ir (arba) problemoms, susijusioms su Portalu, galite susisiekti el. paštu dovanusarasas@gmail.com. Mes padarysime viską, ką galime, ir atsakysime kaip įmanoma greičiau.")
                }

                TermsRoute.Type.Privacy -> buildString {
                    append(
                        "Naudojimosi sąlygos\n" +
                                "Bendrosios nuostatos ir platformos naudojimo taisyklės\n" +
                                "Naudodami programa “dovanusarasas.lt”, Jūs patvirtinate, kad esate susipažinęs su šiomis sutarties sąlygomis ir taisyklėmis (toliau – Taisyklės) bei privatumo taisyklėmis sutinkate su jomis ir įsipareigojate jų laikytis.\n" +
                                "Taisyklės kurias skaitote ir paslaugos apima visus ir bet kokius Jūsų veiksmus, kuriuos Jūs galite atlikti platformoje“dovanusarasas.lt”, įskaitant, bet neapsiribojant, skelbiamos informacijos skaitymu, bet kokio pobūdžio informacijos ir (ar) duomenų pateikimu bei gavimu ir kita (toliau – Paslaugos). Jūs bet kokiu būdu ir forma, naudodamiesi “dovanusarasas.lt” ir (ar) Paslaugomis, įsipareigojate laikytis visų šiose Taisyklėse nustatytų reikalavimų tvarka. Jei nesutinkate prisiimti ir laikytis visų ar dalies šiose Taisyklėse nustatytų įsipareigojimų ar jų nesuprantate, neturite teisės naudotis jokiomis Paslaugomis ir (ar) “dovanusarasas.lt” sistema ir svetaine.\n" +
                                "Registruodamiesi platformoje, Jūs įsipareigojate pateikti teisingus ir išsamius Registracijos duomenis. Registruotis platformoje, naudojantis svetimais duomenimis, yra draudžiama. “dovanusarasas.lt” turi teisę savo nuožiūra spręsti apie Jūsų pateiktų Registracijos duomenų teisingumą, išsamumą ir tikslumą.\n" +
                                "Asmens duomenys\n" +
                                "Registruodamasis plaftormoje ir pateikdamas “dovanusarasas.lt” duomenis arba naudodamasis Paslaugomis, Jūs sutinkate, jog “dovanusarasas.lt” valdytų ir tvarkytų juos Lietuvos Respublikoje ir už jos ribų šios sutarties vykdymo tikslais Paslaugoms teikti, tiesioginei rinkodarai vykdyti ir statistikai rinkti. Taip pat sutinkate su privatumo taisyklėmis.\n" +
                                "\n" +
                                "\n" +
                                "Platformos naudojimosi taisyklės\n" +
                                "\n" +
                                "\n" +
                                "Platforma yra skirta vartotojams sudaryti norimų dovanų sąrašą su nuorodomis į kitas svetaines  ir juo pasidalinti su kitais platformos vartotojais. \n" +
                                "Vartotojams suteikiama galimybė matyti, kurios norimos dovanos yra laisvos ir kurios rezervuotos. Platforma neprisiima atsakomybės užtikrinti šių statusų fakto atitikimą - tai pačių vartotojų atsakomybė.\n" +
                                "Platforma neįsipareigoja užtikrinti pateiktų kainų tikrumo, tai yra trečiųjų šalių pateikta arba vartotojų sukurta informacija.\n" +
                                "Platforma pasilieka teisę deaktyvuoti vartotoją ar pašalinti sąrašą kilus įtarimui, jog jis neatitinka teisės aktų ar moralės normų.\n" +
                                "Prieinamumas ir atsakomybė \n" +
                                "Nors dedame visas pastangas, kad Svetainė būtų prieinama 24/7, mes neprisiimame atsakomybės, jei dėl techninių priežasčių bus negalima užtikrinti nepertraukiamo platformos prieinamumo. \n" +
                                "Intelektinė nuosavybė\n" +
                                "Visos teisės į Svetaines ir joje esančius kūrinius yra saugomos. Joks Svetainėje esantis Turinys ar kitokia informacija negali būti atgaminamas, padaromas viešai prieinamu arba platinamas be išankstinio raštiško “dovanusarasas.lt” sutikimo.\n" +
                                "Klientų aptarnavimas\n" +
                                "Iškilus klausimams ir (arba) problemoms, susijusioms su Portalu, su dovanusarasas.lt galite susisiekti el. paštu dovanusarasas@gmail.com. Mes padarysime viską, ką galime, ir atsakysime kaip įmanoma greičiau.\n" +
                                "\n" +
                                "\n" +
                                "Privatumo politika\n" +
                                "Redaguota ir atnaujinta: 2024-08-08\n" +
                                "Bendrosios nuostatos\n" +
                                "Ši duomenų apsaugos politika taikoma internetiniam portalui www.dovanusarasas.lt („Portalas“), kurį valdo MB Produktyviai. Vienas iš svarbiausių mūsų prioritetų yra mūsų lankytojų privatumas. Šiame privatumo politikos dokumente pateikiama informacija, kurią renka ir registruoja Dovanų sąrašas, tipai ir kaip mes ją naudojame.\n" +
                                "Visus duomenis renkame, saugome ir tvarkome, vadovaudamiesi Europos Sąjungos Bendruoju Duomenų Apsaugos Reglamentu Nr. 2016/679, kitų teisės aktų nuostatomis ir neperduodame jokioms trečiosioms šalims, nebent tai padaryti būtume įpareigoti teisės aktais arba jeigu tai būtų būtina mūsų paslaugoms teikti.\n" +
                                "Sutikimas\n" +
                                "Perskaitę šį dokumentą, jūs sužinosite, kaip saugomi ir kam naudojami jūsų pateikti asmens duomenys. Naudodamiesi mūsų svetaine, jūs sutinkate su mūsų privatumo politika ir sutinkate su jos sąlygomis. . Portalas gali būti atnaujinamas ir pildomas naujomis funkcijomis, todėl atitinkamai atnaujinamos ir šios nuostatos. Jeigu kiltų klausimų dėl Privatumo politikos, susisiekite su mumis el. paštu dovanusarasas@gmail.com. Šios Privatumo politikos nuostatos galioja visiems Portalo lankytojams, taip pat veiksmams, kuriuos jie gali atlikti Portale.\n" +
                                "Renkama informacija\n" +
                                "Asmeninė informacija, kurią prašoma pateikti, ir priežastys, dėl kurių prašoma ją pateikti, jums bus paaiškintos tuo metu, kai prašysime pateikti asmeninę informaciją.\n" +
                                "Vartotojo registracija\n" +
                                "Tam, kad galėtumėte naudotis dovanų sąrašo sukūrimo funkcija, jums reikalinga Dovanų sąrašo paskyra. Šiam tikslui Portale turite užsiregistruoti kaip vartotojas. Tam, kad galėtumėte naudotis mūsų Portalo funkcija sukurti ir išsaugoti dovanų sąrašą, jums reikalinga Dovanų Sąrašo paskyra, kurią galite susikurti per “Google” ar “Facebook” paskyras ar sukūrus paskyrą su elektroniniu paštu ir slaptažodžiu.\n" +
                                " Duomenų rinkimas ir tvarkymas\n" +
                                "5.1 . Vartotojo registracija\n" +
                                "\n" +
                                "5.1.1. Registracija per jūsų “Google“ ir Facebook paskyras\n" +
                                "\n" +
                                "\n" +
                                "Jeigu registruojatės per Google ar Facebook paskyrą, iš mūsų Portalo būsite nukreipti į Google arba Facebook platformą. Prisijungiant prie dovanusarasas.lt, bus tvarkomi ir saugiai laikomi šie jūsų duomenys:\n" +
                                "Vardą\n" +
                                "Pavardę\n" +
                                "Elektroninio pašto adresą\n" +
                                "\t5.1.2 Registracija naudojant el. Pašto adresą ir slaptažodį\n" +
                                "Jeigu registruojatės sukurdami paskyrą naudojant el.pašto adresą ir slaptažodį, tvarkomi\n" +
                                "duomenys yra el.pašto adresas ir slaptažodis\n" +
                                "5.2 Kaip naudojame jūsų informaciją\n" +
                                "Surinktą informaciją naudojame įvairiais būdais, įskaitant:\n" +
                                "• Teikti, valdyti ir prižiūrėti mūsų svetainę;\n" +
                                "• Tobulinti, pritaikyti ir plėsti mūsų svetainę;\n" +
                                "• Suprasti ir analizuoti, kaip naudojatės mūsų svetaine;\n" +
                                "• Naujų produktų, paslaugų, funkcijų ir funkcionalumo kūrimui;\n" +
                                "• Bendrauti su jumis tiesiogiai arba per vieną iš mūsų partnerių, įskaitant klientų aptarnavimo, atnaujinimų ir kitos su svetaine susijusios informacijos teikimo, rinkodaros ir reklamos tikslais; \n" +
                                "• Siųsti jums el. Laiškus Jums sutikus;\n" +
                                "• Ieškoti sukčiavimo atvejų ir užkirsti jiems kelią;\n" +
                                "5.3  Mes galime organizuoti ir vykdyti žaidimus socialinio tinklo „Facebook“ ar „Instagram“ paskyrose.\n" +
                                "Visų Duomenų subjektų, kurie dalyvauja žaidime, bus tvarkomi asmens duomenys, kuriuos jie pateikia naudodamiesi socialinio tinklo paskyra (pvz. profilio nuotrauka, vardas, pavardė, elektroninio pašto adresas).\n" +
                                "Žaidimo laimėtojui identifikuoti ir apdovanoti mes tvarkysime tokius žaidimo laimėtojo asmens duomenis: vardą, pavardę, telefono numerį ir adresą; duomenys, kuriuos Duomenų subjektas pateikia naudodamasis socialinio tinklo paskyra (pvz. profilio nuotrauka, vardas, pavardė, elektroninio pašto adresas).\n" +
                                "Dalyvaudamas žaidime, Duomenų subjektas sutinka, kad Duomenų valdytojas tvarkys nurodytus asmens duomenis.\n" +
                                "Nurodyti asmens duomenys saugomi 4 savaites po žaidimo pabaigos.\n" +
                                "CCPA privatumo teisės\n" +
                                "Pagal CCPA, be kitų teisių nustatymus, vartotojai turi teisę:\n" +
                                "• reikalauti, kad vartotojo asmens duomenis renkanti įmonė atskleistų, kokių kategorijų ir kokius konkrečius asmens duomenis apie vartotojus ji surinko. • reikalauti, kad įmonė ištrintų visus įmonės surinktus vartotojo asmens duomenis. • reikalauti, kad įmonė, kuri parduoda vartotojo asmens duomenis, neparduotų vartotojo asmens duomenų.\n" +
                                "Jei pateikiate užklausą, atsakymą turime pateikti per mėnesį. Jei norite pasinaudoti bet kuria iš šių teisių, susisiekite su mumis dovanusarasas@gmail.com\n" +
                                "BDAR duomenų apsaugos teisės\n" +
                                "Norėtume įsitikinti, kad žinote visas savo teises, susijusias su duomenų apsauga. Kiekvienas naudotojas turi šias teises:\n" +
                                "Teisė susipažinti - turite teisę prašyti savo asmens duomenų kopijų. Už šią paslaugą galime imti nedidelį mokestį.\n" +
                                "Teisė į ištaisymą - turite teisę prašyti, kad ištaisytume bet kokią informaciją, kuri, jūsų manymu, yra netiksli. Taip pat turite teisę prašyti papildyti, jūsų manymu, neišsamią informaciją.\n" +
                                "Teisė ištrinti duomenis - tam tikromis sąlygomis turite teisę prašyti, kad ištrintume jūsų asmens duomenis.\n" +
                                "Teisė apriboti duomenų tvarkymą - tam tikromis sąlygomis turite teisę prašyti, kad apribotume jūsų asmens duomenų tvarkymą.\n" +
                                "Teisė nesutikti su duomenų tvarkymu - tam tikromis sąlygomis turite teisę nesutikti, kad tvarkytume jūsų asmens duomenis.\n" +
                                "Teisė į duomenų perkėlimą - turite teisę prašyti, kad tam tikromis sąlygomis perduotume surinktus duomenis kitai organizacijai arba tiesiogiai jums.\n" +
                                "Jei pateikiate užklausą, atsakymą turime pateikti per mėnesį. Jei norite pasinaudoti bet kuria iš šių teisių, susisiekite su mumis.\n" +
                                "Kontaktai iškilus klausimams\n" +
                                "dovanusarasas@gmail.com\n"
                    )
                }
            }
            Text(text, Modifier.verticalScroll(rememberScrollState()).padding(it))
        }
    )
}
