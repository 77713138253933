package com.koduok.lists.feature.register

import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.providers.builtin.Email
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface RegisterService {
    suspend fun register(register: Register)
}

private class RegisterRepository : RegisterService {
    override suspend fun register(register: Register) {
        when (register) {
            is EmailRegister -> runSupabase {
                auth.signUpWith(Email) {
                    email = register.email
                    password = register.password
                }
            }
        }
    }
}

sealed class Register

data class EmailRegister(val email: String, val password: String) : Register()

internal fun Module.registerRepository() {
    factoryOf(::RegisterRepository) bind RegisterService::class
}
