package com.koduok.lists.feature.home

import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.supabase.model.SupabaseRegistry
import com.koduok.lists.supabase.supabase
import io.github.jan.supabase.postgrest.postgrest
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface HomeService {
    suspend fun getFeaturedRegistries(): List<Registry>
    suspend fun getAppUserRegistries(): List<Registry>
}

private val featuredIds = listOf(
    "c093ba54-17df-43d1-9839-b308c58dbfb9",
    "1d02f2df-bdda-345d-848a-b53bc7bf48a6",
    "71f94d35-adea-3b72-ad89-81d6aaba1ba6",
    "bcaf6606-eb91-3574-9c6a-b378f198dbc4",
    "0e792d64-537e-3cf4-8021-60509603631d",
    "d91cb35c-674a-4b3d-885e-36f8fcfaefa6",
    "379cb009-6b41-33d1-8d83-9ea766638861",
    "0debf3c3-626a-3739-bdda-fd08efda2cb4",
    "b473f7e2-8cef-3290-84ef-87e5c7deabd3",
    "d4c2c993-c191-3cbb-88cb-4ddb1083d93a",
    "fa7af1ff-fc1c-3555-b70d-1c9dde997b9f",
    "fa2a9302-3901-3cd6-afbd-0e05c917711f",
    "30917e12-d5a9-35da-a333-44c55f837e46",
    "8ea80f1d-fa0d-39d8-8703-923d0f4f76fc",
    "935bc7db-3dd4-3771-bf78-e3a89d908e24",
).map { RegistryId(it) }


private class HomeRepository(private val appUserService: AppUserService) : HomeService {

    override suspend fun getFeaturedRegistries(): List<Registry> {
        return supabase.postgrest
            .from("registries")
            .select(SupabaseRegistry.columns) {
                filter {
                    isIn("id", featuredIds.map { id -> id.value })
                }
            }
            .decodeList<SupabaseRegistry>()
            .map { it.toRegistry() }
            .filter { featuredIds.contains(it.id) }
            .sortedBy { featuredIds.indexOf(it.id) }
    }

    override suspend fun getAppUserRegistries(): List<Registry> {
        val appUserId = appUserService.requireAppUserId().value
        return supabase.postgrest
            .from("registries")
            .select(SupabaseRegistry.columns) {
                filter { eq("owner_id", appUserId) }
            }
            .decodeList<SupabaseRegistry>()
            .map { it.toRegistry() }
    }
}

internal fun Module.homeRepository() {
    factoryOf(::HomeRepository) bind HomeService::class
}
