package com.koduok.lists.component.button

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.size
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonColors
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.LocalContentColor
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.dp
import com.koduok.lists.ext.optionalDraw
import com.koduok.lists.theme.AppColors

@Composable
fun Button(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    loading: Boolean = false,
    colors: ButtonColors = ButtonDefaults.buttonColors(),
    content: @Composable RowScope.() -> Unit,
) = Button(
    onClick = { if (!loading) onClick() },
    modifier,
    enabled = enabled,
    colors = colors,
    content = {
        Box(contentAlignment = Alignment.Center) {
            Row(Modifier.optionalDraw(!loading)) {
                content()
            }

            if (loading) {
                CircularProgressIndicator(Modifier.size(16.dp), color = LocalContentColor.current, strokeWidth = 2.dp, strokeCap = StrokeCap.Round)
            }
        }
    }
)

@Composable
fun ButtonInverse(
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    enabled: Boolean = true,
    loading: Boolean = false,
    colors: ButtonColors = ButtonDefaults.buttonColors(containerColor = LocalContentColor.current, contentColor = AppColors.primary),
    content: @Composable RowScope.() -> Unit,
) = Button(
    onClick = { if (!loading) onClick() },
    modifier,
    enabled = enabled,
    colors = colors,
    content = {
        Box(contentAlignment = Alignment.Center) {
            Row(Modifier.optionalDraw(!loading)) {
                content()
            }

            if (loading) {
                CircularProgressIndicator(Modifier.size(16.dp), color = LocalContentColor.current, strokeWidth = 2.dp, strokeCap = StrokeCap.Round)
            }
        }
    }
)
