package com.koduok.lists.feature.registry

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.ClickedSaveEntry
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.*
import com.koduok.lists.feature.registry.CopyEntryViewModel.Effect
import com.koduok.lists.feature.registry.CopyEntryViewModel.Effect.Close
import com.koduok.lists.feature.registry.CopyEntryViewModel.State
import com.koduok.lists.model.DisplayEntry
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class CopyEntryViewModel(
    private val registryId: RegistryId,
    private val displayEntry: DisplayEntry,
    private val service: RegistryService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        loadRegistries()
    }

    fun onClickErrorAction() {
        when {
            state.registriesState.isFailed -> loadRegistries()
            state.saveState.isFailed -> updateState { state.savingReset() }
        }
    }

    fun onClickRegistry(registry: Registry) {
        copyEntry(registry.id)
    }

    private fun loadRegistries() = launchUniqueIfNotRunning("loadRegistries") {
        updateState { state.loading() }
        runCatching { service.getAppUserRegistries() }
            .onSuccess { updateState { state.loaded(it) } }
            .onFailureLogNonFatal("Failed to load app user registries for copying an entry $registryId") { updateState { state.failed(it) } }
    }

    private fun copyEntry(toRegistryId: RegistryId) = launchUniqueIfNotRunning("copyEntry") {
        analytics.track(ClickedSaveEntry(registryId, toRegistryId, displayEntry.entry))
        updateState { state.saving() }
        runCatching { service.copyEntry(displayEntry.entry, registryId, toRegistryId) }
            .onSuccess { updateState { state.saved() } }
            .onSuccess { effect(Close) }
            .onFailureLogNonFatal("Failed to copy entry") { updateState { state.savingFailed(it) } }
    }

    data class State(
        internal val registriesState: LoadState<List<Registry>> = LoadState.Idle,
        internal val saveState: LoadState<Unit> = LoadState.Idle,
    ) {
        val screenState = LoadState.merge(registriesState, saveState) { registries, _ -> registries }

        internal fun loading() = copy(registriesState = registriesState.asLoading())
        internal fun loaded(registries: List<Registry>) = copy(registriesState = registries.asLoaded())
        internal fun failed(error: Throwable) = copy(registriesState = registriesState.asFailed(error))

        internal fun savingReset() = copy(saveState = LoadState.Idle)
        internal fun saving() = copy(saveState = saveState.asLoading())
        internal fun saved() = copy(saveState = Unit.asLoaded())
        internal fun savingFailed(error: Throwable) = copy(saveState = error.asFailed())
    }

    sealed class Effect {
        data object Close : Effect()
    }
}

internal fun Module.copyEntryViewModel() {
    factoryOf(::CopyEntryViewModel)
}
