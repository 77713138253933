package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyGridScope
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.feature.registry.RegistryItem
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.AppDimens

@Composable
internal fun UserHomeScreen(
    userRegistries: List<Registry>,
    featuredRegistries: List<Registry>,
    onClickNewRegistry: () -> Unit,
    onClickRegistry: (RegistryId) -> Unit,
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
    onClickTerms: () -> Unit,
    onClickPrivacy: () -> Unit,
) {
    BoxWithConstraints {
        val maxContentWidth = AppDimens.maxContentWidth
        val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 8.dp
        val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 8.dp)

        LazyVerticalGrid(
            GridCells.Adaptive(AppDimens.gridItemMinWidth),
            Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            contentPadding = contentPadding
        ) {
            homeScreenUserRegistries(userRegistries, onClickRegistry, onClickNewRegistry)
            homeScreenFeaturedRegistries(featuredRegistries, onClickRegistry)

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HomeScreenFooter(onClickTerms, onClickPrivacy, onClickSocialFacebook, onClickSocialInstagram)
            }
        }
    }
}

private fun LazyGridScope.homeScreenUserRegistries(
    userRegistries: List<Registry>,
    onClickRegistry: (RegistryId) -> Unit,
    onClickNewRegistry: () -> Unit,
) {
    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        HomeSection(
            "Tavo sąrašai",
            contentPadding = PaddingValues(top = 0.dp, start = 8.dp, end = 8.dp, bottom = 32.dp),
        ) {
            Button(onClickNewRegistry) {
                Text("Kurti sąrašą".uppercase())
            }
        }
    }

    items(userRegistries) { registry ->
        RegistryItem(
            onClick = { onClickRegistry(registry.id) },
            registry = registry,
            modifier = Modifier.fillMaxWidth().aspectRatio(0.75f),
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {}
}

