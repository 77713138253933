package com.koduok.lists.component.topBar

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.TopAppBarScrollBehavior
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppType
import lists.shared_compose.generated.resources.Res
import lists.shared_compose.generated.resources.logo
import org.jetbrains.compose.resources.vectorResource

@Composable
fun TopAppBar(
    title: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    scrollBehavior: TopAppBarScrollBehavior? = null,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable RowScope.() -> Unit = {},
) {
    androidx.compose.material3.TopAppBar(
        title,
        modifier,
        navigationIcon,
        actions,
        scrollBehavior = scrollBehavior,
        colors = TopAppBarDefaults.topAppBarColors(
            scrolledContainerColor = AppColors.surfaceVariant,
            containerColor = AppColors.surfaceVariant,
            navigationIconContentColor = AppColors.onSurfaceVariant,
            titleContentColor = AppColors.onSurfaceVariant,
            actionIconContentColor = AppColors.onSurfaceVariant,
        ),
    )
}

@Composable
fun BackgroundTopAppBar(
    title: @Composable () -> Unit,
    modifier: Modifier = Modifier,
    scrollBehavior: TopAppBarScrollBehavior? = null,
    navigationIcon: @Composable () -> Unit = {},
    actions: @Composable RowScope.() -> Unit = {},
) {
    androidx.compose.material3.TopAppBar(
        title,
        modifier,
        navigationIcon,
        actions,
        scrollBehavior = scrollBehavior,
        colors = TopAppBarDefaults.topAppBarColors(
            scrolledContainerColor = AppColors.surfaceVariant,
            containerColor = AppColors.background,
            navigationIconContentColor = AppColors.onBackground,
            titleContentColor = AppColors.onBackground,
            actionIconContentColor = AppColors.onBackground,
        ),
    )
}

@Composable
fun BrandTopBarTitle(
    visible: Boolean = true,
) {
    AnimatedVisibility(visible) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Icon(vectorResource(Res.drawable.logo), "Logo", Modifier.size(48.dp), tint = AppColors.primary)
            Spacer(Modifier.width(12.dp))
            Text("Dovanų sąrašas", style = AppType.titleLarge)
        }
    }
}
