package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.*
import androidx.compose.material3.LocalContentColor
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppType

@Composable
fun HomeSection(
    title: String,
    subtitle: String? = null,
    containerColor: Color? = null,
    contentColor: Color? = AppColors.primary,
    contentPadding: PaddingValues = PaddingValues(vertical = 32.dp, horizontal = 8.dp),
    content: (@Composable ColumnScope.() -> Unit)? = null,
) {
    Box(
        modifier = if (containerColor != null) {
            Modifier.drawWithCache {
                val topLeft = Offset(-9999999f, 0f)
                val newSize = Size(9999999f * 2f, size.height)

                onDrawBehind {
                    drawRect(containerColor, topLeft = topLeft, size = newSize)
                }
            }
        } else Modifier,
        contentAlignment = Alignment.Center,
    ) {
        Column(
            Modifier.padding(contentPadding),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            CompositionLocalProvider(LocalContentColor provides (contentColor ?: LocalContentColor.current)) {
                Text(
                    title,
                    Modifier.padding(horizontal = 8.dp),
                    style = AppType.displaySmall,
                    textAlign = TextAlign.Center
                )

                if (subtitle != null) {
                    Text(
                        subtitle,
                        Modifier.padding(top = 8.dp, start = 8.dp, end = 8.dp),
                        style = AppType.titleLarge,
                        textAlign = TextAlign.Center
                    )
                }

                if (content != null) {
                    Spacer(Modifier.height(24.dp))
                    content()
                }
            }
        }
    }
}