package com.koduok.lists.feature.profile

import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.auth.auth
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface ProfileService {
    suspend fun signOut()
}

private class ProfileRepository : ProfileService {
    override suspend fun signOut() = runSupabase {
        runCatching { auth.signOut() }
        auth.clearSession()
    }
}

internal fun Module.profileRepository() {
    factoryOf(::ProfileRepository) bind ProfileService::class
}
