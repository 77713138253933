@file:OptIn(ExperimentalLayoutApi::class)

package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.automirrored.rounded.Send
import androidx.compose.material.icons.rounded.AddCircle
import androidx.compose.material.icons.rounded.Celebration
import androidx.compose.material.icons.rounded.Http
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppIconsAutoMirrored
import com.koduok.lists.theme.LocalWindowSizeClass

@Composable
fun HowToCreateRegistrySection(
    onClickNewRegistry: () -> Unit,
) {
    val items = remember {
        listOf(
            HowToCreateItem(AppIcons.Http, "Prisijunk prie dovanusarasas.lt"),
            HowToCreateItem(AppIcons.AddCircle, "Įrašyk norų idėjas"),
            HowToCreateItem(AppIconsAutoMirrored.Send, "Pasidalink su draugais ir artimaisiais"),
            HowToCreateItem(AppIcons.Celebration, "Džiaukimės išpildę norus ir svajones"),
        )
    }

    val widthClass = LocalWindowSizeClass.current.widthSizeClass

    when (widthClass) {
        WindowWidthSizeClass.Compact -> {
            Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
                items.forEach { StepByStepItem(it.icon, it.text, Modifier.fillMaxWidth()) }
            }
        }

        WindowWidthSizeClass.Medium -> {
            Column(verticalArrangement = Arrangement.spacedBy(8.dp)) {
                Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    items.take(2).forEach { StepByStepItem(it.icon, it.text, Modifier.weight(1f)) }
                }
                Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                    items.drop(2).forEach { StepByStepItem(it.icon, it.text, Modifier.weight(1f)) }
                }
            }
        }

        WindowWidthSizeClass.Expanded -> {
            Row(horizontalArrangement = Arrangement.spacedBy(8.dp)) {
                items.forEach { StepByStepItem(it.icon, it.text, Modifier.weight(1f)) }
            }
        }
    }

    Button(onClickNewRegistry, Modifier.padding(top = 32.dp)) {
        Text("Sukurk savo sąrašą".uppercase())
    }
}

@Immutable
data class HowToCreateItem(
    val icon: ImageVector,
    val text: String,
)
