package com.koduok.lists.feature

sealed class LoadState<out T> {
    companion object {
        inline fun <T1, T2, reified R> merge(
            state1: LoadState<T1>,
            state2: LoadState<T2>,
            map: (LoadedValue<T1>?, LoadedValue<T2>?) -> LoadedValue<R>?,
        ): LoadState<R> = merge(
            listOf(state1, state2),
            map = {
                @Suppress("UNCHECKED_CAST") map(it[0] as LoadedValue<T1>?, it[1] as LoadedValue<T2>?)
            },
        )

        inline fun <T1, T2, T3, reified R> merge(
            state1: LoadState<T1>,
            state2: LoadState<T2>,
            state3: LoadState<T3>,
            map: (LoadedValue<T1>?, LoadedValue<T2>?, LoadedValue<T3>?) -> LoadedValue<R>?,
        ): LoadState<R> = merge(
            listOf(state1, state2, state3),
            map = {
                @Suppress("UNCHECKED_CAST") map(it[0] as LoadedValue<T1>?, it[1] as LoadedValue<T2>?, it[2] as LoadedValue<T3>?)
            },
        )

        inline fun <T1, T2, T3, T4, reified R> merge(
            state1: LoadState<T1>,
            state2: LoadState<T2>,
            state3: LoadState<T3>,
            state4: LoadState<T4>,
            map: (LoadedValue<T1>?, LoadedValue<T2>?, LoadedValue<T3>?, LoadedValue<T4>?) -> LoadedValue<R>?,
        ): LoadState<R> = merge(
            listOf(state1, state2, state3, state4),
            map = {
                @Suppress("UNCHECKED_CAST") map(it[0] as LoadedValue<T1>?, it[1] as LoadedValue<T2>?, it[2] as LoadedValue<T3>?, it[3] as LoadedValue<T4>?)
            },
        )

        inline fun <reified R> merge(
            states: List<LoadState<*>>,
            map: (List<LoadedValue<Any?>?>) -> LoadedValue<R>?,
        ): LoadState<R> {
            val loadedValue = map(states.map { it.loadedValue })

            return when {
                states.any { it is Loading } -> Loading(loadedValue)

                states.any { it is Failed } -> {
                    val errorState = states.filterIsInstance<Failed<*>>().first()
                    Failed(errorState.error, loadedValue)
                }

                loadedValue != null -> Loaded(loadedValue)

                else -> Idle
            }
        }

        fun mergeOnlyFailures(vararg states: LoadState<*>): LoadState<Unit> = merge(states.toList()) { LoadedValue(Unit) }.onlyFailures()
    }

    abstract val loadedValue: LoadedValue<T>?

    val valueOrNull get() = loadedValue?.value
    val errorOrNull get() = (this as? Failed)?.error
    val isFailed get() = this is Failed

    data object Idle : LoadState<Nothing>() {
        override val loadedValue get() = null
    }

    data class Loading<T>(
        override val loadedValue: LoadedValue<T>? = null,
        val show: Boolean = loadedValue == null,
    ) : LoadState<T>() {
        constructor(value: T) : this(LoadedValue(value))
    }

    data class Failed<T>(
        val error: Throwable,
        override val loadedValue: LoadedValue<T>? = null,
        val show: Boolean = true,
    ) : LoadState<T>() {
        constructor(error: Throwable, value: T, show: Boolean = true) : this(error, LoadedValue(value), show)
    }

    data class Loaded<T>(override val loadedValue: LoadedValue<T>) : LoadState<T>() {
        val value = loadedValue.value

        constructor(value: T) : this(LoadedValue(value))
    }
}

/**
 * Wrapper class to represent previously loaded value in Loading and Failed states. This is needed, because actual loaded value
 * can be null. For this reason we need a wrapper to indicate if we actually have previously loaded value or not.
 */
data class LoadedValue<out T>(val value: T)

fun <T> LoadState<T>.asLoading(loadedValue: LoadedValue<T>? = this.loadedValue, show: Boolean = loadedValue == null) = LoadState.Loading(loadedValue, show)
fun <T> LoadState<T>.asFailed(error: Throwable, loadedValue: LoadedValue<T>? = this.loadedValue) = LoadState.Failed(error, loadedValue)
fun <T> T.asLoaded() = LoadState.Loaded(this)
fun <T> T.asLoadedValue() = LoadedValue(this)
fun <T> Throwable.asFailed() = LoadState.Failed<T>(this)

/**
 * Keeps state the same, but value is mapped.
 */
inline fun <T, reified R> LoadState<T>.mapValue(map: (T?) -> R?): LoadState<R> {
    val result = map(valueOrNull)

    val loadedValue = when {
        result != null -> LoadedValue(result)
        null is R -> LoadedValue(null as R)
        else -> null
    }

    return when {
        this is LoadState.Loading -> LoadState.Loading(loadedValue)
        this is LoadState.Failed<T> -> LoadState.Failed(error, loadedValue)
        this is LoadState.Loaded<T> && loadedValue != null -> LoadState.Loaded(loadedValue)
        else -> LoadState.Idle
    }
}

/**
 * Tries to map to [LoadState.Loaded] when mapped value is non-null. [LoadState.Failed] always takes precedence.
 */
inline fun <T, reified R> LoadState<T>.mapState(map: LoadState<T>.() -> R?): LoadState<R> {
    val result = map()

    val loadedValue = when {
        result != null -> LoadedValue(result)
        null is R -> LoadedValue(null as R)
        else -> null
    }

    return when {
        this is LoadState.Failed<T> -> LoadState.Failed(error, loadedValue)
        loadedValue != null -> LoadState.Loaded(loadedValue)
        this is LoadState.Loading -> LoadState.Loading(loadedValue)
        else -> LoadState.Idle
    }
}

/**
 * A good use case for this is when you always want to render content but still want to handle errors.
 */
fun <T> LoadState<T>.onlyFailures(): LoadState<Unit> = mapState {}
