package com.koduok.lists.model

import com.benasher44.uuid.uuidFrom
import com.koduok.lists.ext.*
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
data class Registry(
    val id: RegistryId,
    val title: String,
    val description: String?,
    val isTemplate: Boolean,
    val visibility: RegistryVisibility,
    val copyId: RegistryId?,
    val ownerId: UserId?,
    val image: UrlImage?,
) {
    fun isAdmin(userId: UserId) = ownerId == userId

    val analytics
        get() = id.analytics + mapOf(
            "has_description" to (description != null),
            "is_template" to isTemplate,
            "visibility" to visibility.name,
        )
}

@JvmInline
@Serializable
value class RegistryId(private val internalValue: String) {
    /**
     * Supports old firebase IDs as well by converting them to UUID
     */
    val value
        get() = runCatching {
            uuidFrom(internalValue)
        }.recover {
            uuidFromText(internalValue)
        }.getOrThrow().toString()

    val analytics: Map<String, Any> get() = mapOf("registry_id" to value)
    fun analytics(prefix: String): Map<String, Any> = mapOf("${prefix}registry_id" to value)
}

enum class RegistryVisibility(val value: String) {
    Public("public"), Private("private");

    companion object {
        fun from(value: String?) = when (value) {
            "public" -> Public
            "private" -> Private
            else -> Private
        }
    }
}

fun fRegistry() = Registry(
    id = fRegistryId(),
    title = fTextSentenceShort(),
    description = fText2Sentences(),
    ownerId = fUserId(),
    isTemplate = fBoolean(),
    copyId = fRegistryId(),
    visibility = RegistryVisibility.entries.random(),
    image = fUrlImage(),
)

fun fRegistryId() = RegistryId(fTextId())
