package com.koduok.lists.feature.forgotPassword

import com.koduok.lists.errors.PasswordDoesNotMatchError
import com.koduok.lists.model.ClientEnvironment
import com.koduok.lists.model.ResetPassword
import com.koduok.lists.routes.ResetPasswordRoute
import com.koduok.lists.supabase.runSupabase
import io.github.jan.supabase.auth.auth
import io.github.jan.supabase.auth.parseSessionFromFragment
import io.github.jan.supabase.auth.status.SessionSource
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

internal fun Module.forgotPasswordService() = factoryOf(::ForgotPasswordRepository) bind ForgotPasswordService::class

interface ForgotPasswordService {
    suspend fun forgotPassword(email: String)
    suspend fun resetPassword(pending: ResetPassword.Pending, newPassword: String, repeatPassword: String)
}

private class ForgotPasswordRepository(
    private val clientEnvironment: ClientEnvironment,
) : ForgotPasswordService {
    override suspend fun forgotPassword(email: String) {
        runSupabase { auth.resetPasswordForEmail(email, redirectUrl = ResetPasswordRoute(ResetPassword.Setup).url(clientEnvironment)) }
    }

    override suspend fun resetPassword(pending: ResetPassword.Pending, newPassword: String, repeatPassword: String) {
        if (newPassword != repeatPassword) {
            throw PasswordDoesNotMatchError
        }

        runSupabase {
            val session = auth.parseSessionFromFragment(pending.rawFragment)
            val user = auth.retrieveUser(session.accessToken)
            val newSession = session.copy(user = user)
            auth.importSession(newSession, source = SessionSource.External)
            auth.updateUser(redirectUrl = null) { password = newPassword }
        }
    }
}
