package com.koduok.lists.feature.home

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material.icons.rounded.Star
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.button.ButtonInverse
import com.koduok.lists.component.media.Image
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.*
import lists.shared_compose.generated.resources.*

@Composable
internal fun GuestHomeScreen(
    featuredRegistries: List<Registry>,
    onClickNewRegistry: () -> Unit,
    onClickRegistry: (RegistryId) -> Unit,
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
    onClickTerms: () -> Unit,
    onClickPrivacy: () -> Unit,
) {
    BoxWithConstraints {
        val maxContentWidth = AppDimens.maxContentWidth
        val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 8.dp
        val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 8.dp)

        LazyVerticalGrid(
            GridCells.Adaptive(AppDimens.gridItemMinWidth),
            Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            contentPadding = contentPadding
        ) {
            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HeaderSection(onClickNewRegistry)
            }

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                InfoSection(onClickNewRegistry)
            }

            homeScreenFeaturedRegistries(featuredRegistries, onClickRegistry)

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HomeSection(title = "Kaip sukurti dovanų sąrašą?", contentPadding = PaddingValues(vertical = 32.dp, horizontal = 0.dp)) {
                    HowToCreateRegistrySection(onClickNewRegistry)
                }
            }

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                TestimonialsSection()
            }

            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                HomeScreenFooter(onClickTerms, onClickPrivacy, onClickSocialFacebook, onClickSocialInstagram)
            }
        }
    }
}

@Composable
private fun HeaderSection(onClickNewRegistry: () -> Unit) = HomeSection(
    title = "Dovanų sąrašas",
    subtitle = "Gauk dovanas, kurios Tave džiugins",
    contentPadding = PaddingValues(0.dp)
) {
    Button(onClickNewRegistry) {
        Text("Sukurk savo sąrašą".uppercase())
    }

    Spacer(Modifier.height(24.dp))
    Row(Modifier.height(AppDimens.featuredImageHeight).fillMaxWidth(), horizontalArrangement = Arrangement.spacedBy(8.dp)) {
        val count = when (LocalWindowSizeClass.current.widthSizeClass) {
            WindowWidthSizeClass.Compact -> 2
            WindowWidthSizeClass.Medium -> 3
            else -> 4
        }
        listOf(Res.drawable.home1, Res.drawable.home2, Res.drawable.home3, Res.drawable.home4).take(count).forEach {
            Image(it, Modifier.fillMaxHeight().weight(1f), contentScale = ContentScale.Crop)
        }
    }
}

@Composable
private fun InfoSection(onClickNewRegistry: () -> Unit) = HomeSection(
    title = "Kodėl verta kurti dovanų sąrašą?",
    subtitle = "Gimtadieniams, krikštynoms, vestuvėms, Kalėdoms... Jūsų draugams ir šeimos nariams bus lengviau išrinkti tai, ko iš tiesų norite, o jums užtikrinti, kad gausite tai, ko jums tikrai reikia ir apie ką svajojate. Daugiau jokių nereikalingų dovanų!",
    containerColor = AppColors.primary,
    contentColor = AppColors.onPrimary,
) {
    ButtonInverse(onClickNewRegistry) {
        Text("Sukurk savo sąrašą".uppercase())
    }
}

@Composable
private fun TestimonialsSection() {
    HomeSection("Atsiliepimai", containerColor = AppColors.primary, contentColor = AppColors.onPrimary) {
        val testimonials = remember {
            listOf(
                TestimonialData(
                    "Labai patogu, ypač vaiko gimtadieniui",
                    "Ir svečiams nereikia sukti galvos, ir vaikas gauna tai, ko reikia ir nori.",
                    "Ada",
                    "@skaitaunuogimimo"
                ),
                TestimonialData(
                    "Dovanų sąrašas man labai pasiteisinusi platforma",
                    "Galite iš kart kopijuoti idėjas, prasitrinti, papildyti pagal poreikį ir siųsti dovanosiantiems. Svarbiausia, kad nupirkus dovaną galima aiškiai tai išskirti, tad nėra rizikos, kad dovanos susidubliuos.",
                    "Austėja",
                    "@lifetakestwo",
                ),
                TestimonialData(
                    "Ar žinot būdą kaip niekada negauti blogų dovanų?",
                    "Pasakyti žmonėms, ko tiksliai norėtumėt gauti dovanų. Paprasčiau nei paprasta!",
                    "Kristina",
                    "@kristina.razmi",
                ),
            )
        }

        val smallScreen = LocalWindowSizeClass.current.widthSizeClass == WindowWidthSizeClass.Compact
        if (smallScreen) {
            Column(verticalArrangement = Arrangement.spacedBy(16.dp)) {
                testimonials.forEach {
                    Testimonial(it, Modifier.fillMaxWidth().padding(vertical = 16.dp, horizontal = 24.dp))
                }
            }
        } else {
            Row(Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.SpaceEvenly) {
                testimonials.forEach {
                    Testimonial(it, Modifier.weight(1f).padding(horizontal = 16.dp))
                }
            }
        }
    }
}

@Composable
private fun Testimonial(
    data: TestimonialData,
    modifier: Modifier = Modifier,
) = Column(modifier, horizontalAlignment = Alignment.CenterHorizontally) {
    Row {
        repeat(5) {
            Icon(AppIcons.Star, contentDescription = "", modifier = Modifier.size(24.dp))
        }
    }
    Spacer(Modifier.height(8.dp))
    Text(data.title.uppercase(), style = AppType.bodyLarge, fontWeight = FontWeight.SemiBold, textAlign = TextAlign.Center)
    Spacer(Modifier.height(16.dp))
    Text("\"${data.message}\"", textAlign = TextAlign.Center)
    Spacer(Modifier.height(16.dp))
    Text(data.author, fontWeight = FontWeight.SemiBold, textAlign = TextAlign.Center)
    Text(data.userTag, textAlign = TextAlign.Center)
}

private data class TestimonialData(
    val title: String,
    val message: String,
    val author: String,
    val userTag: String,
)

@Preview
@Composable
private fun Preview() {
    Text("Asd")
}