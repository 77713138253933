package com.koduok.lists.feature.registry

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material.icons.rounded.ContentCopy
import androidx.compose.material.icons.rounded.Delete
import androidx.compose.material.icons.rounded.Edit
import androidx.compose.material3.Icon
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.coerceAtLeast
import androidx.compose.ui.unit.dp
import com.arkivanov.decompose.ComponentContext
import com.koduok.lists.component.button.Button
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.dialog.AlertDialog
import com.koduok.lists.component.dialog.Dialog
import com.koduok.lists.component.topBar.BackgroundTopAppBar
import com.koduok.lists.component.topBar.BrandTopBarTitle
import com.koduok.lists.component.topBar.TopAppBarActionButton
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.Collapsed
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.PreferExpanded
import com.koduok.lists.component.topBar.TopBarBackButton
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.appUser.AppUserButton
import com.koduok.lists.feature.login.LoginDialog
import com.koduok.lists.feature.login.LoginViewModel
import com.koduok.lists.feature.login.rememberLoginViewModel
import com.koduok.lists.feature.registry.RegistryViewModel.DialogContent.AskDeleteRegistry
import com.koduok.lists.feature.registry.RegistryViewModel.Effect.Close
import com.koduok.lists.feature.registry.RegistryViewModel.Effect.OpenLogin
import com.koduok.lists.feature.registry.RegistryViewModel.Effect.OpenRegistryEdit
import com.koduok.lists.feature.registry.RegistryViewModel.Effect.OpenUrl
import com.koduok.lists.model.Action
import com.koduok.lists.model.DisplayEntry
import com.koduok.lists.model.EntryAction
import com.koduok.lists.model.EntryId
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.model.toAction
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.rememberResultRequest
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.RegistryEditRoute
import com.koduok.lists.routes.RegistryEditRoute.Result.Success
import com.koduok.lists.routes.RegistryRoute
import com.koduok.lists.theme.AppDimens
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppType
import org.koin.core.parameter.parametersOf

val registryScreen
    get() = screen<RegistryRoute> {
        val router = LocalRouter.current
        val uriHandler = LocalUriHandler.current
        val loginViewModel = rememberLoginViewModel(it)
        val viewModel = rememberViewModel<RegistryViewModel> { parametersOf(it.registryId) }

        val registryEditResultRequest = rememberResultRequest<RegistryEditRoute.Result, RegistryEditRoute> { result ->
            when (result) {
                Success -> viewModel.refresh()
            }
        }

        val state by viewModel.collectAsStateAndEffects { effect ->
            when (effect) {
                Close -> router.pop()
                is OpenUrl -> uriHandler.openUri(effect.url)
                is OpenRegistryEdit -> registryEditResultRequest.request(RegistryEditRoute(effect.editType))
                is OpenLogin -> loginViewModel.showLogin(effect.reason)
            }
        }

        RegistryScreen(
            loginViewModel = loginViewModel,
            state = state,
            onClickBack = viewModel::onClickBack,
            onClickRetry = viewModel::onClickRetry,
            onClickEntry = viewModel::onClickEntry,
            onClickEdit = viewModel::onClickEdit,
            onClickDelete = viewModel::onClickDelete,
            onClickCopy = viewModel::onClickCopyRegistry,
        )

        when (val dialogContent = state.dialogContent) {
            AskDeleteRegistry -> AlertDialog(
                onDismissRequest = viewModel::onDismissDialog,
                confirmAction = Action(viewModel::onClickConfirmDelete, "Ištrinti"),
                dismissAction = Action(viewModel::onDismissDialog, "Atmesti"),
                icon = AppIcons.Delete,
                title = "Ar tikrai norite ištrinti sąrašą?",
            )

            is RegistryViewModel.DialogContent.CopyEntry -> SaveEntryDialog(
                dialogContent.registryId,
                dialogContent.displayEntry,
                onDismiss = viewModel::onDismissDialog,
            )

            null -> Unit
        }

        LoginDialog(loginViewModel)
    }

@Composable
private fun RegistryScreen(
    loginViewModel: LoginViewModel,
    state: RegistryViewModel.State,
    onClickBack: () -> Unit,
    onClickRetry: () -> Unit,
    onClickEntry: (DisplayEntry, EntryAction) -> Unit,
    onClickEdit: () -> Unit,
    onClickDelete: () -> Unit,
    onClickCopy: () -> Unit,
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior()
    Scaffold(
        modifier = Modifier.nestedScroll(scrollBehavior.nestedScrollConnection),
        topBar = {
            BackgroundTopAppBar(
                scrollBehavior = scrollBehavior,
                title = { BrandTopBarTitle() },
                navigationIcon = { TopBarBackButton(onClickBack) },
                actions = {
                    if (state.screenState.valueOrNull?.registry?.isTemplate == false) {
                        TopAppBarActionButton(onClickCopy, PreferExpanded, "Kopijuoti") {
                            Icon(AppIcons.ContentCopy, "Kopijuoti")
                        }
                    }
                    if (state.screenState.valueOrNull?.canEdit == true) {
                        TopAppBarActionButton(onClickEdit, PreferExpanded, "Redaguoti") {
                            Icon(AppIcons.Edit, "Redaguoti")
                        }

                        TopAppBarActionButton(onClickDelete, PreferExpanded, "Trinti") {
                            Icon(AppIcons.Delete, "Trinti")
                        }
                    }
                    AppUserButton(loginViewModel, Collapsed)
                },
            )
        },
    ) {
        FullContent(state.screenState, onClickRetry.toAction(), Modifier.padding(it)) { displayData ->
            val registry = displayData.registry
            if (registry == null) {
                NotFound()
            } else {
                RegistryView(
                    registry,
                    displayData.displayEntries,
                    state.loadingEntryId,
                    onClickEntry,
                    onClickCopy,
                )
            }
        }
    }
}

@Composable
private fun NotFound() {
    Text("Nerasta")
}

@Composable
private fun RegistryView(
    registry: Registry,
    displayEntries: List<DisplayEntry>,
    loadingEntryId: EntryId?,
    onClickEntry: (DisplayEntry, EntryAction) -> Unit,
    onClickCopy: () -> Unit,
) {
    BoxWithConstraints {
        val maxContentWidth = AppDimens.maxContentWidth
        val horizontalPadding = ((maxWidth - maxContentWidth) / 2).coerceAtLeast(0.dp) + 8.dp
        val contentPadding = PaddingValues(horizontal = horizontalPadding, vertical = 16.dp)

        LazyVerticalGrid(
            GridCells.Adaptive(AppDimens.gridItemMinWidth),
            Modifier.fillMaxSize(),
            verticalArrangement = Arrangement.spacedBy(8.dp),
            horizontalArrangement = Arrangement.spacedBy(8.dp),
            contentPadding = contentPadding,
        ) {
            item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
                Header(registry, onClickCopy)
            }

            items(displayEntries) {
                EntryItem(it, onClickEntry, it.entry.id == loadingEntryId, Modifier.fillMaxSize())
            }
        }
    }
}

@Composable
private fun Header(registry: Registry, onClickCopy: () -> Unit) = Column {
    Text(registry.title, style = AppType.headlineMedium)

    val description = registry.description
    if (description != null) {
        Text(description, Modifier.padding(top = 8.dp), style = AppType.bodyLarge)
    }

    if (registry.isTemplate) {
        Button(onClickCopy, Modifier.padding(top = 24.dp)) { Text("Kopijuoti sąrašą") }
    }

    Spacer(Modifier.height(24.dp))
}

@Composable
private fun ComponentContext.SaveEntryDialog(
    registryId: RegistryId,
    displayEntry: DisplayEntry,
    onDismiss: () -> Unit,
) {
    rememberViewModel<CopyEntryViewModel> { parametersOf(registryId, displayEntry) }

    Dialog(
        onDismissRequest = onDismiss,
        content = {

        }
    )

}
