package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Scaffold
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.TopAppBarScrollBehavior
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.nestedscroll.nestedScroll
import androidx.compose.ui.platform.LocalUriHandler
import androidx.lifecycle.compose.LifecycleResumeEffect
import com.koduok.lists.component.content.FullContent
import com.koduok.lists.component.topBar.BackgroundTopAppBar
import com.koduok.lists.component.topBar.BrandTopBarTitle
import com.koduok.lists.component.topBar.TopAppBarActionButtonType.PreferExpanded
import com.koduok.lists.ext.OnEffect
import com.koduok.lists.ext.collectAsStateAndEffects
import com.koduok.lists.ext.rememberViewModel
import com.koduok.lists.feature.appUser.AppUserButton
import com.koduok.lists.feature.home.HomeViewModel.DisplayData.GuestDisplayData
import com.koduok.lists.feature.home.HomeViewModel.DisplayData.UserDisplayData
import com.koduok.lists.feature.home.HomeViewModel.Effect.OpenLogin
import com.koduok.lists.feature.home.HomeViewModel.Effect.OpenTerms
import com.koduok.lists.feature.home.HomeViewModel.Effect.OpenUrl
import com.koduok.lists.feature.home.HomeViewModel.Effect.ShowEditRegistry
import com.koduok.lists.feature.home.HomeViewModel.Effect.ShowRegistry
import com.koduok.lists.feature.login.LoginDialog
import com.koduok.lists.feature.login.LoginViewModel
import com.koduok.lists.feature.login.LoginViewModel.Effect.LoggedIn
import com.koduok.lists.feature.login.rememberLoginViewModel
import com.koduok.lists.model.RegistryId
import com.koduok.lists.navigation.LocalRouter
import com.koduok.lists.navigation.screen
import com.koduok.lists.routes.HomeRoute
import com.koduok.lists.routes.RegistryEditRoute
import com.koduok.lists.routes.RegistryRoute
import com.koduok.lists.routes.TermsRoute

val homeScreen
    get() = screen<HomeRoute> {
        val router = LocalRouter.current
        val uriHandler = LocalUriHandler.current
        val loginViewModel = rememberLoginViewModel(it)
        val viewModel = rememberViewModel<HomeViewModel>()
        val state by viewModel.collectAsStateAndEffects { effect ->
            when (effect) {
                is ShowRegistry -> router.push(RegistryRoute(effect.registryId))
                is ShowEditRegistry -> router.push(RegistryEditRoute(effect.registryEditType))
                is OpenLogin -> loginViewModel.showLogin(effect.reason)
                is OpenUrl -> uriHandler.openUri(effect.url)
                is OpenTerms -> router.push(TermsRoute(effect.type))
            }
        }

        loginViewModel.OnEffect { effect ->
            when (effect) {
                is LoggedIn -> viewModel.onResume()
                else -> Unit
            }
        }

        HomeScreen(
            loginViewModel = loginViewModel,
            state = state,
            onClickNewRegistry = viewModel::onClickCreateRegistry,
            onClickRegistry = viewModel::onClickRegistry,
            onClickSocialFacebook = viewModel::onClickSocialFacebook,
            onClickSocialInstagram = viewModel::onClickSocialInstagram,
            onClickTerms = viewModel::onClickTerms,
            onClickPrivacy = viewModel::onClickPrivacy,
        )

        LoginDialog(loginViewModel)

        LifecycleResumeEffect(Unit) {
            viewModel.onResume()
            onPauseOrDispose { }
        }
    }

@Composable
private fun HomeScreen(
    loginViewModel: LoginViewModel,
    state: HomeViewModel.State,
    onClickNewRegistry: () -> Unit,
    onClickRegistry: (RegistryId) -> Unit,
    onClickSocialFacebook: () -> Unit,
    onClickSocialInstagram: () -> Unit,
    onClickTerms: () -> Unit,
    onClickPrivacy: () -> Unit,
) {
    val scrollBehavior = TopAppBarDefaults.pinnedScrollBehavior()
    Scaffold(
        modifier = Modifier.nestedScroll(scrollBehavior.nestedScrollConnection),
        topBar = { TopAppBar(loginViewModel, scrollBehavior) },
    ) { padding ->
        FullContent(state.screenState, Modifier.padding(padding)) { displayData ->
            when (displayData) {
                is GuestDisplayData -> GuestHomeScreen(
                    displayData.featuredRegistries,
                    onClickNewRegistry,
                    onClickRegistry,
                    onClickSocialFacebook,
                    onClickSocialInstagram,
                    onClickTerms,
                    onClickPrivacy,
                )

                is UserDisplayData -> UserHomeScreen(
                    displayData.userRegistries,
                    displayData.featuredRegistries,
                    onClickNewRegistry,
                    onClickRegistry,
                    onClickSocialFacebook,
                    onClickSocialInstagram,
                    onClickTerms,
                    onClickPrivacy,
                )
            }
        }
    }
}

@Composable
private fun TopAppBar(
    loginViewModel: LoginViewModel,
    scrollBehavior: TopAppBarScrollBehavior,
) {
    BackgroundTopAppBar(
        title = { BrandTopBarTitle() },
        actions = { AppUserButton(loginViewModel, PreferExpanded) },
        scrollBehavior = scrollBehavior,
    )
}
