package com.koduok.lists.feature.home

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.LocalWindowSizeClass

@Composable
fun StepByStepItem(
    icon: ImageVector,
    text: String,
    modifier: Modifier = Modifier,
) {
    val isSmall = LocalWindowSizeClass.current.widthSizeClass == WindowWidthSizeClass.Compact

    if (isSmall) {
        Row(modifier.background(AppColors.surfaceVariant, CircleShape).padding(4.dp), verticalAlignment = Alignment.CenterVertically) {
            Box(Modifier.padding(8.dp).size(48.dp).background(AppColors.background, CircleShape).padding(8.dp), contentAlignment = Alignment.Center) {
                Icon(icon, null, Modifier.size(24.dp))
            }
            Text(text, Modifier.padding(start = 4.dp))
        }
    } else {
        Column(modifier.background(AppColors.surfaceVariant, AppShapes.large).padding(8.dp), horizontalAlignment = Alignment.CenterHorizontally) {
            Box(Modifier.padding(8.dp).size(48.dp).background(AppColors.background, CircleShape).padding(8.dp), contentAlignment = Alignment.Center) {
                Icon(icon, null, Modifier.size(24.dp))
            }
            Text(text, Modifier.padding(top = 8.dp), textAlign = TextAlign.Center, minLines = 2)
        }
    }
}

//    Box(modifier) {
//    Column(
//        Modifier.padding(start = 28.dp, top = 28.dp).fillMaxWidth().background(AppColors.surfaceVariant, AppShapes.large).padding(16.dp),
//        horizontalAlignment = Alignment.CenterHorizontally
//    ) {
//        Image(
//            icon,
//            Modifier.padding(horizontal = 16.dp).size(48.dp),
//            colorFilter = ColorFilter.tint(AppColors.onSurfaceVariant),
//        )
//
//        Spacer(Modifier.height(16.dp))
//
//        Box {
//            Text(
//                "",
//                minLines = 3,
//            )
//            Text(
//                text,
//                Modifier.fillMaxWidth()
//                    .heightIn(min = 96.dp)
//                    .background(AppColors.surface, AppShapes.extraLarge)
//                    .padding(horizontal = 16.dp, vertical = 4.dp)
//                    .wrapContentHeight(),
//                textAlign = TextAlign.Center,
//            )
//        }
//    }
//
//    Text(
//        number,
//        Modifier.size(56.dp).background(AppColors.primary, CircleShape).wrapContentHeight(),
//        textAlign = TextAlign.Center,
//        style = AppType.headlineMedium,
//        color = AppColors.onPrimary,
//    )
//}
