package com.koduok.lists.feature.login

import com.koduok.lists.analytics.Analytics
import com.koduok.lists.analytics.events.ClickedPrivacyEvent
import com.koduok.lists.analytics.events.ClickedTermsEvent
import com.koduok.lists.analytics.events.LoggedInEvent
import com.koduok.lists.analytics.events.LoginRequestedEvent
import com.koduok.lists.ext.onFailureLogNonFatal
import com.koduok.lists.feature.LoadState
import com.koduok.lists.feature.ViewModel
import com.koduok.lists.feature.asFailed
import com.koduok.lists.feature.asLoading
import com.koduok.lists.feature.login.LoginViewModel.Effect
import com.koduok.lists.feature.login.LoginViewModel.Effect.*
import com.koduok.lists.feature.login.LoginViewModel.LoginReason.Finish
import com.koduok.lists.feature.login.LoginViewModel.State
import com.koduok.lists.routes.LoginRoute2
import com.koduok.lists.routes.LoginUrlFragment
import com.koduok.lists.routes.TermsRoute
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf

class LoginViewModel(
    private val redirectRoute: LoginRoute2,
    private val service: LoginService,
    private val analytics: Analytics,
) : ViewModel<State, Effect>(State()) {

    init {
        if (redirectRoute.fragment.isValid) {
            finishLogin(redirectRoute.fragment)
        }
    }

    fun showLogin(reason: LoginReason) = updateState { state.show(reason) }
    fun hideLogin() = updateState { state.hide() }

    fun onClickErrorAction() = updateState { state.idle() }

    fun onEmailChanged(email: String) = updateState { state.copy(email = email) }
    fun onPasswordChanged(password: String) = updateState { state.copy(password = password) }
    fun onToggleShowPassword() = updateState { state.showPasswordToggled() }
    fun onClickLogin() = launchUniqueIfNotRunning("login") {
        updateState { state.loading() }

        val login = EmailLogin(state.email, state.password)
        analytics.track(LoginRequestedEvent(login))
        runCatching { service.login(login, redirectRoute) }
            .onSuccess { analytics.track(LoggedInEvent) }
            .onSuccess { effect(LoggedIn) }
            .onSuccess { hideLogin() }
            .onFailureLogNonFatal("Login failed") { updateState { state.failed(it) } }
    }

    fun onClickGoogleLogin() = launchUniqueIfNotRunning("google login") {
        updateState { state.loading() }

        analytics.track(LoginRequestedEvent(GoogleLogin))
        runCatching { service.login(GoogleLogin, redirectRoute) }
            .onFailureLogNonFatal("Google login failed") { updateState { state.failed(it) } }
    }

    fun onClickFacebookLogin() = launchUniqueIfNotRunning("facebook login") {
        updateState { state.loading() }

        analytics.track(LoginRequestedEvent(FacebookLogin))
        runCatching { service.login(FacebookLogin, redirectRoute) }
            .onFailureLogNonFatal("Facebook login failed") { updateState { state.failed(it) } }
    }

    fun onClickRegister() {
        hideLogin()
        effect(ShowRegister)
    }

    fun onClickForgotPassword() = effect(ShowForgotPassword)

    fun onClickPrivacyPolicy() {
        analytics.track(ClickedPrivacyEvent)
        effect(OpenTerms(TermsRoute.Type.Privacy))
    }

    fun onClickTermsAndConditions() {
        analytics.track(ClickedTermsEvent)
        effect(OpenTerms(TermsRoute.Type.Terms))
    }


    private fun finishLogin(fragment: LoginUrlFragment) = launchUniqueIfNotRunning("finish login") {
        updateState { state.loading() }

        runCatching { service.finishLogin(fragment) }
            .onSuccess { analytics.track(LoggedInEvent) }
            .onSuccess { effect(LoggedIn) }
            .onSuccess { hideLogin() }
            .onFailureLogNonFatal("Finish login failed") { updateState { state.failed(it) } }
    }

    data class State(
        val visible: Boolean = false,
        val reason: LoginReason = Finish,
        val email: String = "",
        val password: String = "",
        val showPassword: Boolean = false,
        val loginState: LoadState<Unit> = LoadState.Loaded(Unit),
    ) {
        internal fun show(reason: LoginReason) = copy(visible = true, reason = reason)
        internal fun hide() = idle().copy(visible = false, email = "", password = "", showPassword = false)

        internal fun idle() = copy(loginState = LoadState.Loaded(Unit))
        internal fun loading() = copy(loginState = loginState.asLoading(show = true), visible = true)
        internal fun failed(error: Throwable) = copy(loginState = loginState.asFailed(error))
        internal fun showPasswordToggled() = copy(showPassword = !showPassword)
    }

    sealed class Effect {
        data object ShowForgotPassword : Effect()
        data object ShowRegister : Effect()
        data object LoggedIn : Effect()
        data class OpenTerms(val type: TermsRoute.Type) : Effect()
    }

    enum class LoginReason { UserInitiated, CreateRegistry, CopyRegistry, Finish }
}

internal fun Module.loginViewModel2() {
    factoryOf(::LoginViewModel)
}
