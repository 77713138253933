package com.koduok.lists.ext

import io.ktor.http.*

val String.urlDomain: String
    get() {
        val domain = replace("http(s)?://|www(2)?\\.|/.*".toRegex(), "")
        return if (domain == "collabs.shop") {
            "mideer"
        } else {
            domain
        }
    }

val String.urlWithUtmSource get() = URLBuilder(this).apply { parameters.append("utm_source", "dovanusarasas") }.buildString()