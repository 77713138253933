package com.koduok.lists.feature.home

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridItemSpan
import androidx.compose.foundation.lazy.grid.LazyGridScope
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import com.koduok.lists.feature.registry.RegistryItem
import com.koduok.lists.model.Registry
import com.koduok.lists.model.RegistryId
import com.koduok.lists.theme.AppType

internal fun LazyGridScope.homeScreenFeaturedRegistries(
    featuredRegistries: List<Registry>,
    onClickRegistry: (RegistryId) -> Unit,
) {
    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        HomeSection(
            title = "Dovanų sąrašo idėjos",
            subtitle = "Nori dovanų gauti atrinktas dovanas, tačiau neturi laiko susikurti dovanų sąrašą? Pasinaudok jau sukurtais dovanų sąrašais. Tau tereikia tik nukopijuoti sąrašą, jį peržiūrėti ir atnaujinti pagal save.",
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        Column {
            Text(
                "Pagal pomėgius",
                Modifier.fillMaxWidth(),
                style = AppType.titleLarge,
                textAlign = TextAlign.Center,
                fontWeight = FontWeight.Medium,
            )
            HorizontalDivider(Modifier.padding(vertical = 16.dp))
        }
    }

    items(featuredRegistries.take(7)) { registry ->
        RegistryItem(
            onClick = { onClickRegistry(registry.id) },
            registry = registry,
            modifier = Modifier.fillMaxWidth(),
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {}

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {
        Column {
            Text(
                "Pagal amžių",
                Modifier.padding(top = 32.dp).fillMaxWidth(),
                style = AppType.titleLarge,
                textAlign = TextAlign.Center,
                fontWeight = FontWeight.Medium,
            )
            HorizontalDivider(Modifier.padding(vertical = 16.dp))
        }
    }

    items(featuredRegistries.drop(7)) { registry ->
        RegistryItem(
            onClick = { onClickRegistry(registry.id) },
            registry = registry,
            modifier = Modifier.fillMaxWidth(),
        )
    }

    item(span = { GridItemSpan(currentLineSpan = maxCurrentLineSpan) }) {}
}
