package com.koduok.lists.model

import kotlinx.serialization.Serializable

@Serializable
data class FullRegistry(
    val registry: Registry,
    val entries: List<Entry>,
) {
    fun withReplacedEntry(entry: Entry) = copy(
        entries = entries.map {
            if (it.id == entry.id) {
                entry
            } else {
                it
            }
        }
    )
}
