package com.koduok.lists.feature.registry

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.material.icons.rounded.MoreVert
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.ColorMatrix
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.Card
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.button.IconButton
import com.koduok.lists.component.button.TextButton
import com.koduok.lists.component.media.Image
import com.koduok.lists.ext.formatted
import com.koduok.lists.ext.urlDomain
import com.koduok.lists.model.DisplayEntry
import com.koduok.lists.model.EntryAction
import com.koduok.lists.model.EntryAction.*
import com.koduok.lists.model.fDisplayEntry
import com.koduok.lists.model.fEntry
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppIcons
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.AppType

@Composable
fun EntryItem(
    displayEntry: DisplayEntry,
    onClick: (DisplayEntry, EntryAction) -> Unit,
    isLoading: Boolean,
    modifier: Modifier = Modifier,
) {
    val promotion = displayEntry.entry.promotion
    val background = if (promotion == null) AppColors.surfaceVariant else AppColors.surfaceVariant
    Column(modifier.background(background, AppShapes.medium).clip(AppShapes.medium)) {
        ImageArea(onClick, displayEntry)
        Title(displayEntry)
        Actions(displayEntry, isLoading, onClick)
    }
}

@Composable
private fun ImageArea(
    onClick: (DisplayEntry, EntryAction) -> Unit,
    displayEntry: DisplayEntry,
) = Card(
    onClick = { onClick(displayEntry, Open) },
    modifier = Modifier.fillMaxWidth().aspectRatio(1f),
    colors = CardDefaults.cardColors(containerColor = AppColors.surfaceVariant, contentColor = AppColors.onSurfaceVariant),
    shape = AppShapes.medium,
    content = {
        Box(Modifier.fillMaxSize()) {
            Image(
                displayEntry.entry.image,
                Modifier.matchParentSize(),
                contentScale = ContentScale.Crop,
                colorFilter = if (displayEntry.entry.reserved) ColorFilter.colorMatrix(ColorMatrix().apply { setToSaturation(0f) }) else null
            )

            val domain = displayEntry.entry.url?.urlDomain
            if (domain != null) {
                Text(
                    domain,
                    Modifier.align(Alignment.TopStart).padding(8.dp).background(AppColors.surfaceVariant, shape = AppShapes.extraSmall).padding(4.dp),
                    style = AppType.labelSmall,
                    fontWeight = FontWeight.SemiBold,
                )
            }

            val price = displayEntry.entry.price
            if (price != null) {
                Text(
                    price.formatted(),
                    Modifier.align(Alignment.BottomEnd).padding(8.dp).background(AppColors.surfaceVariant, shape = AppShapes.extraSmall).padding(4.dp),
                    style = AppType.bodyMedium,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.SemiBold,
                    minLines = 1,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    color = if (displayEntry.entry.reserved) LocalContentColor.current.copy(0.5f) else LocalContentColor.current
                )
            }

            val promotion = displayEntry.entry.promotion
            if (promotion != null) {
                val textColor = AppColors.onTertiaryContainer
                Text(
                    promotion.title,
                    Modifier.align(Alignment.BottomStart).padding(8.dp).background(AppColors.tertiaryContainer, shape = AppShapes.extraSmall).padding(4.dp),
                    style = AppType.bodyMedium,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.SemiBold,
                    minLines = 1,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    color = if (displayEntry.entry.reserved) textColor.copy(0.5f) else textColor
                )
            }
        }

    })

@Composable
private fun Title(
    displayEntry: DisplayEntry,
) = Box(Modifier.fillMaxWidth().padding(start = 16.dp, end = 16.dp, top = 16.dp), contentAlignment = Alignment.Center) {
    Text(
        "",
        style = AppType.bodyMedium,
        textAlign = TextAlign.Center,
        fontWeight = FontWeight.Medium,
        minLines = 2,
    )
    Text(
        displayEntry.entry.title,
        style = AppType.bodyMedium,
        textAlign = TextAlign.Center,
        fontWeight = FontWeight.Medium,
        maxLines = 2,
        overflow = TextOverflow.Ellipsis,
        color = if (displayEntry.entry.reserved) LocalContentColor.current.copy(0.5f) else LocalContentColor.current
    )
}

@Composable
private fun Actions(
    displayEntry: DisplayEntry,
    isLoading: Boolean,
    onClick: (DisplayEntry, EntryAction) -> Unit,
) {
    val entryAction = displayEntry.action
    val moreEntryActions = displayEntry.moreActions
    if (entryAction != null || moreEntryActions.isNotEmpty()) {
        Box(Modifier.fillMaxWidth()) {
            if (entryAction != null) {
                EntryActionButton(
                    action = entryAction,
                    isLoading = isLoading,
                    onClick = { onClick(displayEntry, entryAction) },
                    modifier = Modifier.fillMaxWidth()
                )
            }

            if (moreEntryActions.isNotEmpty()) {
                var expanded by remember { mutableStateOf(false) }
                Box(Modifier.align(Alignment.BottomEnd)) {
                    IconButton(onClick = { expanded = true }) {
                        Icon(AppIcons.MoreVert, contentDescription = "Daugiau")
                    }

                    DropdownMenu(expanded, onDismissRequest = { expanded = false }) {
                        moreEntryActions.forEach { moreEntryAction ->
                            DropdownMenuItem(
                                text = { Text(moreEntryAction.displayTitle) },
                                onClick = {
                                    expanded = false
                                    onClick(displayEntry, moreEntryAction)
                                }
                            )
                        }
                    }
                }
            }
        }

    } else {
        Spacer(Modifier.height(16.dp))
    }
}

@Composable
private fun EntryActionButton(
    action: EntryAction,
    isLoading: Boolean,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
) {
    val color = when (action) {
        Release -> AppColors.error
        else -> Color.Unspecified
    }

    TextButton(
        onClick = onClick,
        modifier = modifier,
        loading = isLoading,
        shape = RectangleShape,
        enabled = action.enabled,
        colors = ButtonDefaults.textButtonColors(contentColor = color),
        content = { Text(action.displayTitle) },
    )
}

private val EntryAction.displayTitle
    get() = when (this) {
        Reserve -> "Rezervuoti"
        Release -> "Atšaukti"
        Reserved -> "Rezervuota"
        Save -> "Išsaugoti"
        Open -> "Atidaryti"
    }


@Preview
@Composable
private fun Preview() = PreviewContainer {
    val items = listOf(
        fDisplayEntry(entry = fEntry(reserved = false), action = Reserve),
        fDisplayEntry(entry = fEntry(reserved = true), action = Release),
        fDisplayEntry(entry = fEntry(reserved = true), action = Reserved),
        fDisplayEntry(entry = fEntry(reserved = false), action = Reserve),
        fDisplayEntry(entry = fEntry(reserved = false), action = null)
    )

    LazyVerticalGrid(
        GridCells.Adaptive(180.dp),
        Modifier.padding(16.dp),
        horizontalArrangement = Arrangement.spacedBy(16.dp),
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        items(items) {
            EntryItem(
                displayEntry = it,
                onClick = { _, _ -> },
                isLoading = false,
                modifier = Modifier.width(200.dp)
            )
        }

        item {
            EntryItem(
                displayEntry = items.first(),
                onClick = { _, _ -> },
                isLoading = true,
                modifier = Modifier.width(200.dp)
            )
        }
    }
}
