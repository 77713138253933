package com.koduok.lists.feature.registry

import com.koduok.lists.feature.appUser.AppUserService
import com.koduok.lists.model.*
import com.koduok.lists.supabase.model.SupabaseEntry
import com.koduok.lists.supabase.model.SupabaseRegistry
import com.koduok.lists.supabase.runSupabase
import com.koduok.lists.supabase.supabase
import io.github.jan.supabase.postgrest.postgrest
import org.koin.core.module.Module
import org.koin.core.module.dsl.factoryOf
import org.koin.dsl.bind

interface RegistryService {
    suspend fun getAppUserRegistries(): List<Registry>
    suspend fun getRegistry(id: RegistryId): Registry?
    suspend fun deleteRegistry(id: RegistryId)
    suspend fun reserve(entryId: EntryId): Entry
    suspend fun release(entryId: EntryId): Entry
    suspend fun copyEntry(entry: Entry, fromRegistryId: RegistryId, toRegistryId: RegistryId)
}

private class RegistryRepository(private val appUserService: AppUserService) : RegistryService {
    override suspend fun getAppUserRegistries(): List<Registry> {
        val appUserId = appUserService.requireAppUserId().value
        return supabase.postgrest
            .from("registries")
            .select(SupabaseRegistry.columns) {
                filter {
                    eq("registry_users.user_id", appUserId)
                }
            }
            .decodeList<SupabaseRegistry>()
            .filter { supabaseRegistry -> supabaseRegistry.registryUsers.orEmpty().any { it.userId == appUserId && it.role == RegistryRole.Admin.value } }
            .map { it.toRegistry() }
    }

    override suspend fun getRegistry(id: RegistryId): Registry? = supabase.postgrest.from("registries").select(SupabaseRegistry.columns) {
        filter {
            eq("id", id.value)
        }
    }.decodeSingleOrNull<SupabaseRegistry>()?.toRegistry()

    override suspend fun deleteRegistry(id: RegistryId) {
        runSupabase {
            postgrest.from("registries").delete {
                filter {
                    eq("id", id.value)
                }
            }
        }
    }

    override suspend fun reserve(entryId: EntryId): Entry {
        val appUserId = appUserService.appUserIdOrNull()
        val supabaseEntry = supabase.postgrest.from("entries").select(SupabaseEntry.columns) {
                filter {
                    eq("id", entryId.value)
                }
        }.decodeSingleOrNull<SupabaseEntry>()!!

        return if (!supabaseEntry.reserved) {
            val newEntry = supabaseEntry.copy(reserved = true, reservedUserId = appUserId?.value)
            supabase.postgrest.from("entries").update(newEntry) { filter { SupabaseEntry::id eq entryId.value } }
            newEntry
        } else {
            supabaseEntry
        }.toEntry()
    }

    override suspend fun release(entryId: EntryId): Entry {
        val appUserId = appUserService.appUserIdOrNull()
        val supabaseEntry = supabase.postgrest.from("entries").select(SupabaseEntry.columns) {
                filter {
                    eq("id", entryId.value)
                }
        }.decodeSingleOrNull<SupabaseEntry>()!!

        return if (supabaseEntry.reserved && (supabaseEntry.reservedUserId == null || supabaseEntry.reservedUserId == appUserId?.value)) {
            val newEntry = supabaseEntry.copy(reserved = false, reservedUserId = null)
            supabase.postgrest.from("entries").update(newEntry) { filter { SupabaseEntry::id eq entryId.value } }
            newEntry
        } else {
            supabaseEntry
        }.toEntry()
    }

    override suspend fun copyEntry(
        entry: Entry,
        fromRegistryId: RegistryId,
        toRegistryId: RegistryId,
    ) {
        TODO("Not yet implemented")
    }
}

internal fun Module.registryRepository() {
    factoryOf(::RegistryRepository) bind RegistryService::class
}
