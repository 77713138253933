package com.koduok.lists.model

import com.koduok.lists.ext.*
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
data class Entry(
    val id: EntryId,
    val title: String,
    val url: String?,
    val image: UrlImage?,
    val reserved: Boolean,
    val reservedUserId: UserId?,
    val price: Money?,
    val promotion: Promotion?,
    val order: Int,
) {
    val analytics
        get() = mapOf(
            "entry_id" to id.value,
            "entry_domain" to url?.urlDomain.orEmpty(),
            "entry_price" to price?.toPlainAmountString().orEmpty(),
            "entry_currency" to price?.currency?.code?.value.orEmpty(),
            "entry_reserved" to reserved,
        )
}

@Serializable
sealed class Promotion {
    abstract val title: String
    abstract val description: String
}

data class DiscountCodePromotion(
    val code: String,
    override val title: String,
    override val description: String,
) : Promotion()

data class DisplayEntry(
    val entry: Entry,
    val action: EntryAction?,
    val moreActions: List<EntryAction>,
)

enum class EntryAction {
    Reserve,
    Release,
    Reserved,
    Save,
    Open,
    ;

    val enabled get() = this != Reserved
}

@JvmInline
@Serializable
value class EntryId(val value: String)

fun fDisplayEntry(
    entry: Entry = fEntry(),
    action: EntryAction? = fEntryActionOrNull(),
    moreActions: List<EntryAction> = fRandomListOf(min = 0, max = EntryAction.entries.size) { fEntryAction() }.distinct(),
) = DisplayEntry(
    entry = entry,
    action = action,
    moreActions = moreActions,
)

fun fEntry(
    reserved: Boolean = fBoolean(),
) = Entry(
    id = fEntryId(),
    title = fTextSentenceShort(),
    url = "https://www.google.com",
    image = fUrlImage(),
    reserved = reserved,
    reservedUserId = fUserId(),
    price = fMoney(),
    promotion = fPromotionOrNull(),
    order = fInt(),
)

fun fPromotionOrNull() = fRandomOfOrNull({ fPromotion() })
fun fPromotion() = fRandomOf(
    { fDiscountCodePromotion() },
)

fun fDiscountCodePromotion() = DiscountCodePromotion(
    code = fTextWord(),
    title = fTextPhrase(),
    description = fText2Sentences()
)

fun fEntryAction() = EntryAction.entries.random()
fun fEntryActionOrNull() = fValueOrNull { fEntryAction() }
fun fEntryId() = EntryId(fTextId())
