package com.koduok.lists.feature.registry

import androidx.compose.desktop.ui.tooling.preview.Preview
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import com.koduok.lists.component.PreviewContainer
import com.koduok.lists.component.media.Image
import com.koduok.lists.model.Registry
import com.koduok.lists.model.fRegistry
import com.koduok.lists.theme.AppColors
import com.koduok.lists.theme.AppShapes
import com.koduok.lists.theme.AppType

@Composable
fun RegistryItem(
    onClick: () -> Unit,
    registry: Registry,
    modifier: Modifier = Modifier,
) {
    Card(
        onClick,
        modifier,
        shape = AppShapes.large,
        colors = CardDefaults.cardColors(containerColor = AppColors.surfaceVariant, contentColor = AppColors.onSurfaceVariant),
    ) {
        Column(Modifier.padding(8.dp), horizontalAlignment = Alignment.CenterHorizontally) {
            Image(
                registry.image,
                Modifier.fillMaxWidth().aspectRatio(1f).clip(AppShapes.medium).background(AppColors.background),
                contentScale = ContentScale.Crop
            )
            Box(
                Modifier.padding(top = 12.dp),
                contentAlignment = Alignment.Center,
            ) {
                Text(
                    "",
                    style = AppType.bodyMedium,
                    minLines = 3,
                )

                Text(
                    registry.title,
                    Modifier.fillMaxWidth(),
                    style = AppType.bodyLarge,
                    textAlign = TextAlign.Center,
                    maxLines = 3,
                    overflow = TextOverflow.Ellipsis
                )
            }

        }
    }
}

@Composable
@Preview
private fun Preview() = PreviewContainer {
    RegistryItem(
        onClick = {},
        registry = fRegistry(),
    )
}
